import { EMAIL_REGEX } from "./utils.js";

export default function formFunctions () {
  const form = document.querySelector(".Rsvp-form");
  const selectValue = document.querySelector(".Rsvp-select--label");
  const submitButton = document.querySelector(".Rsvp-form--submit");

  const validator = {
    valid: {
      firstName: false,
      lastName: false,
      email: false,
    },
    touched: {
      firstName: false,
      lastName: false,
      email: false,
    },
    correctEmail: true,
    realTimeValid: {
      firstName: false,
      lastName: false,
      email: false,
    },
    formValid: false,
  }

  const inputNameTags = [
    {
      nameAttribute: "first_name",
      field: "firstName",
    },
    {
      nameAttribute: "last_name",
      field: "lastName",
    },
    {
      nameAttribute: "email",
      field: "email",
    },
  ];


  const inputFields = inputNameTags.map(({nameAttribute, field}) => {
    return (
      {
        domElement: document.querySelector(`input[name=${ nameAttribute }]`),
        field: field,
      }
    );
  });

  inputFields.forEach(({domElement, field}) => {
    domElement.addEventListener("focus", (e)=> {
      const errorText = document.querySelector(`.Rsvp-error--${ field }`);
      errorText.classList.remove("Rsvp-error--show");
      domElement.classList.remove("Rsvp-input--error");
      domElement.classList.remove("Rsvp-input--hidden");
    });

    domElement.addEventListener("blur", (e)=> {
      validator.touched[field] = true;
      const value = e.target.value;

      validateField(field, value);

      if (!validator.valid[field]) {
        const errorText = document.querySelector(`.Rsvp-error--${ field }`);
        errorText.classList.add("Rsvp-error--show");
        domElement.classList.add("Rsvp-input--hidden");

        if (field === "email") {
          errorText.innerHTML = "Required";
        }

        if (field === "email" && !validator.correctEmail) {
          domElement.classList.add("Rsvp-input--error");
          errorText.innerHTML = "Invalid";
        }
      }
    });

    domElement.addEventListener("keyup", (e)=> {
      const elementValue = e.target.value;
      if (field === "email") {
        const validRegex = EMAIL_REGEX.test(elementValue) ? true : false;
        validator.realTimeValid[field] = validRegex && elementValue ? true : false;
      } else {
        validator.realTimeValid[field] = elementValue ? true : false;
      }

      validateForm();
    });
  })

  function validateField(field, value) {    
    if (field === "email") {
      if(value) {
        validator.correctEmail = EMAIL_REGEX.test(value);
        validator.valid[field] = validator.correctEmail && value;
      } else {
        validator.valid[field] =  !!value;
        validator.correctEmail = true;
      }
    } else {     
      validator.valid[field] = !!value;
    }
  }

  function validateForm() {
    validator.formValid = Object.values(validator.realTimeValid).includes(false) ? false : true;
    submitButton.disabled = validator.formValid ? false : true;
  }
}
