import formFunctions from './form.js';
import navButton from './navigateButton.js';

// add browser name



window.onload = function () {
  formFunctions();
  navButton();
}
