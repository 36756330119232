export function getElementOffsetTop(el) {
  let top = 0;
  let element = el;

  do {
    top += element.offsetTop || 0;
    element = element.offsetParent;
  } while (element);

  return top;
}

export const EMAIL_REGEX = /^(?!.*\.{2})(?!.*["_%+-.]@)[a-zA-Z0-9"]+[a-zA-Z0-9"_%+-.?]+@[a-zA-Z0-9]+[a-zA-Z0-9-]+(?:\.[a-zA-Z\d\-]+)+([a-zA-Z\d\-])$/i;

export const BREAKPOINTS = {
  md: 600,
  lg: 1200,
  xl: 1400,
}
