import ScrollManager from 'window-scroll-manager';
import animateScrollTo from 'animated-scroll-to';
import { getElementOffsetTop, BREAKPOINTS } from './utils.js';

const NAV_BUTTON_BOTTOM_OFFSET_SM = 30;
const ANIMATION_DURATION = {
  sm: 40,
  md: 70,
  lg: 90,
  xl: 100,
};

function getElementOffsetBottom(el) {
    let windowHeight = document.documentElement.clientHeight;
    let elOffsetTop = getElementOffsetTop(el);
    let elHeight = el.getBoundingClientRect().height;
  
    return windowHeight - elHeight - elOffsetTop;
}
  
export default function navButton() {
  const navButton = document.querySelector(".Rsvp-link");
  const formRsvpButton = document.querySelector(".Rsvp-form--title");
  let breakpoint;

  let windowHeight = document.documentElement.clientHeight;
  let windowWidth = window.innerWidth;
  
  let formOffsetTop = getElementOffsetTop(formRsvpButton);
  let NAV_BUTTON_BOTTOM_OFFSET_MD = getElementOffsetBottom(navButton);
  const scrollPosition = window.pageYOffset;
  
  if(windowWidth < BREAKPOINTS.md) {
    breakpoint = 'sm';
  } else if (windowWidth < BREAKPOINTS.lg) {
    breakpoint = 'md';
  } else if(windowWidth < BREAKPOINTS.xl) {
    breakpoint = 'lg';
  } else {
    breakpoint = 'xl';
  }

  let elementOffset = breakpoint === 'sm' ? NAV_BUTTON_BOTTOM_OFFSET_SM : NAV_BUTTON_BOTTOM_OFFSET_MD;
  let animationDuration = ANIMATION_DURATION[breakpoint];

  // if refresh is done on end of page
  if(scrollPosition + windowHeight - elementOffset > formOffsetTop) {
    navButton.style.display = "none";
  }

  window.addEventListener('resize', function(e) {
    windowWidth = document.documentElement.clientWidth;
    let NAV_BUTTON_BOTTOM_OFFSET_MD = getElementOffsetBottom(navButton);

    if(windowWidth < BREAKPOINTS.md) {
      breakpoint = 'sm';
    } else if (windowWidth < BREAKPOINTS.lg) {
      breakpoint = 'md';
    } else if(windowWidth < BREAKPOINTS.xl) {
      breakpoint = 'lg';
    } else {
      breakpoint = 'xl';
    }

    elementOffset = breakpoint === 'sm' ? NAV_BUTTON_BOTTOM_OFFSET_SM : NAV_BUTTON_BOTTOM_OFFSET_MD;
    animationDuration = ANIMATION_DURATION[breakpoint];
  
    formOffsetTop = getElementOffsetTop(formRsvpButton);
    windowHeight = document.documentElement.clientHeight;
  });

  const sm = new ScrollManager();

  window.addEventListener('window-scroll', function(e) {
     const scrollValue = e.detail.scrollPositionY;

     if(scrollValue + windowHeight - elementOffset < formOffsetTop) {
       navButton.style.opacity = 1;
     }

     if(scrollValue + windowHeight - elementOffset > formOffsetTop) {
       let opacityValue = 1 + ((formOffsetTop - windowHeight + elementOffset) - scrollValue) / animationDuration;
       opacityValue = Math.min(1, Math.max(0, opacityValue));
       navButton.style.opacity = opacityValue;
       navButton.style.display = "block";

       if(navButton.style.opacity === '0') {
         navButton.style.display = "none";
       }
     }
    let d = document.documentElement;
   
    if (d.scrollTop + window.innerHeight === d.offsetHeight) {
      navButton.style.opacity = 0;
    }  
  });

  navButton.addEventListener('click', (e) => {
    animateScrollTo(formRsvpButton);
  })
}
